:root {
  --theme-color: #1362ad;
}

body {
  font-family: "SF Pro Display";
  font-size: 16px;
  color: #000;
  background-color: rgb(244 246 248);
}

.link-black {
  color: #000 !important;
}

.login-page {
  min-height: 100vh;
  max-width: 100%;
  background-color: #f4f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/login_bg.jpg");
  object-fit: cover;
}

.login-box {
  flex: 0 1 450px;
}

.login-box .card {
  box-shadow: 0 0 37px rgb(8 21 66 / 5%);
  border: none;
  border-radius: 10px;
}

.login-box .card .card-body {
  padding: 40px;
}

a {
  cursor: pointer;
}

.abs-full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-loader {
  width: 100%;
  height: auto;
}

.relative {
  position: relative;
}

.tint {
  background-color: rgba(0, 0, 0, 0.2);
}

.z-index-1 {
  z-index: 1;
}

.step-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.step-title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.step-round-success {
  border-color: rgb(16, 183, 89);
  background: rgb(16, 183, 89);
  color: #fff;
}

.step-content {
  padding: 0px;
  margin: 0px 0px 0px 20px;
  border-left: 1px solid #cecece;
  padding-left: 35px;
  font-size: 16px;
  list-style: lower-alpha;
}

.step-round-active {
  border-color: rgb(1, 104, 250);
  background: rgb(1, 104, 250);
  color: #fff;
}

.step-content li {
  padding: 5px 0px;
}

.step-content-success,
.step-title-success {
  color: rgb(16, 183, 89);
}

.step-content-active,
.step-title-active {
  color: rgb(1, 104, 250);
}

/* Text Colors */
.text-primary {
  color: var(--theme-color) !important;
}
/* End Text Color */

/* font-size  */
.f-10 {
  font-size: 10px;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

.f-28 {
  font-size: 28px;
}
.f-45 {
  font-size: 45px;
}

.f-500 {
  font-weight: 500;
}

.text-striked {
  text-decoration: line-through !important;
}

.text-underline {
  text-decoration: underline !important;
}

.btn-order-now {
  background-color: #000 !important;
  color: #fff !important;
  padding: 5px 20px;
  border-radius: 20px;
}

.topbar {
  font-size: 13px;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  padding: 8px 20px;
}

/* header css  */
.logo {
  width: 80px;
  max-width: 100%;
}

.sticky-header {
  /* backdrop-filter: blur(20px); */
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}

.header {
  font-size: 0.9rem;
  border-bottom: 1px solid rgb(234, 234, 234);
  min-height: 60px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}

.header-right {
  list-style: none;
  display: flex;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.header-right li {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  font-size: 0.95rem;
}

.header-right li,
.header-right li a {
  color: #000 !important;
}

.header-menu {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  background-color: #fff;
  max-height: 350px;
  overflow: auto;
  border-top: 1px solid rgb(234, 234, 234);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.header-menu .table th {
  width: 200px;
}

.header,
.header-menu {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.header-menu-item {
  cursor: pointer;
}

.header-menu-item:hover .header-menu {
  display: block;
}

.btn-signin {
  font-size: 0.85rem;
  border: 1px solid #000;
  padding: 5px 20px;
}

.hello-block {
  line-height: 16px;
  padding: 0;
}

.page-list-con {
  background: var(--theme-color);
}

.page-list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
}

.page-list li {
  display: inline-block;
}

.page-list li a {
  display: inline-block;
  padding: 8px 20px;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
  font-size: 1rem;
}

.page-list li a:hover {
  opacity: 1;
}

.page-list li .page-list-active {
  opacity: 1;
  border-bottom: 3px solid #fff;
}

.drop-menu-con {
  position: relative;
  cursor: pointer;
}

.drop-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 11px 12px 8px;
  width: max-content;
  max-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  background: rgb(255, 255, 255);
  list-style: none;
  margin: 0;
  padding: 5px 0px;
}

.drop-menu-con:hover .drop-menu {
  display: block;
}

.drop-menu li {
  display: block;
  font-size: 0.9rem;
  padding: 4px 15px;
  margin: 0px !important;
  color: #000 !important;
}

.tab-btn-list-con {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .tab-btn-list-con {
    white-space: normal;
  }
}

.tab-btn-list-con .btn-tab {
  margin-bottom: 0.2rem;
}

.btn-tab {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 0px;
  margin-right: 2rem;
}

.btn-tab-active {
  border-bottom: 2px solid var(--theme-color);
}

.btn-signin {
  font-size: 0.85rem;
  border: 1px solid #000;
  padding: 5px 20px;
}

.hello-block {
  line-height: 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* offers  */

.offers-tab-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.offers-tab-list li {
  display: inline-block;
  margin-right: 25px;
}

.offers-tab-list li a {
  display: inline-block;
  padding-bottom: 2px;
  margin-bottom: 5px;
  color: #000 !important;
  text-decoration: none !important;
  font-size: 18px;
}

.offers-tab-list li .active-border {
  border-bottom: 2px solid #000;
}

.download-btn {
  width: 35px;
  height: 35px;
  font-size: 18px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
}

/* buttons */
.btn {
  border-radius: 0px;
  box-shadow: none !important;
}

.btn-theme {
  background-color: var(--theme-color) !important;
  color: #fff !important;
}

.btn-outline-black {
  border: 1px solid #000;
}

/* orders  */
.order-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

.order-table td,
.order-table th,
.order-table tr {
  border: 0;
}

.order-table td,
.order-table th {
  vertical-align: top;
  padding-bottom: 5px;
}

.order-table th {
  font-weight: 400;
}

.order-table td {
  text-align: right;
}

/* profile css  */

/* modals  */
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  padding: 20px;
}

.modal-container {
  max-width: 80%;
  width: 50%;
  height: auto;
  background: #fff;
  margin: auto;
}

.modal-header {
  position: relative;
  padding: 15px 35px 15px 15px;
  background: rgb(255, 255, 255);
  border-bottom: 4px solid rgb(245, 245, 245);
}

.btn-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  padding: 0;
}

.modal-body {
  padding: 25px 15px 30px;
}

.whatsIcon {
  position: fixed;
  right: 20pt;
  bottom: 18pt;
  z-index: 9998;
  top: auto;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.21);
}

.whatsIcon svg {
  height: 45pt;
  width: 45pt;
}

/* pagination */
.pagination,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0;
}

.page-link {
  color: #000;
}

.page-item.active .page-link {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
}

.page-link:focus {
  box-shadow: none;
}

.table-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.table-responsive {
  margin-bottom: 1rem;
}

.table-responsive .table {
  margin-bottom: 0;
}

.table {
  background-color: #fff;
  font-size: 0.85rem;
}

.pointer {
  cursor: pointer;
}

.vertical-middle {
  vertical-align: middle !important;
}

.vertical-middle-table td,
.vertical-middle-table th {
  vertical-align: middle !important;
}

.card-header {
  background-color: #fff;
}

.apartment-table-con {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.apartment-table {
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
  background: #fff;
}

.apartment-table th {
  font-size: 13px;
  font-weight: 500;
}

.apartment-table th,
.apartment-table td {
  width: 200px;
  padding: 5px 0px;
}

.block-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 98;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.block-select-round {
  width: 24px;
  height: 24px;
  background-color: #ffeb3b;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
  position: absolute;
  top: 5px;
  left: 5px;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.address .form-control {
  min-height: 65px;
}

.bg-high {
  background-color: rgb(107, 107, 255);
}

/* Start Custom Nav */
.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: var(--theme-color);
  background-color: transparent;
  transition: none 0s ease 0s;

  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}

/* End Custom Nav */

/* Mouse Curser Disable  */

.disabled {
  cursor: not-allowed;
}

/* Start Custom Nav */
.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: var(--theme-color);
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}

/* End Custom Nav */
/* mine */
.Swap-head {
  background-color: var(--theme-color);
  color: #fff;
}
.Swap-list {
  background-color: var(--theme-color);
  color: #fff;
}

.border-theme {
  border-color: var(--theme-color) !important;
  border-width: 4px !important;
}

.form-custom-control {
  min-height: 40px;
  height: auto !important;
  max-height: 160px;
}

.drop-down-select {
  margin: 10px 0px;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid #ced4da;
  font-weight: 400;
}

/* Table */

.custom-table-width td {
  max-width: 490px;
  overflow-x: auto;
}

/* ScrollBar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme-color);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Link Section */
.link_section {
  position: sticky;
  position: -webkit-sticky;
  max-height: 100%;
  top: 7rem;
  right: 1rem;
}
/* .details_page_links {
  overflow: auto;
  overflow-x: hidden;
  max-height: 59vh;
  padding-right: 1rem;
} */
.icon_padding {
  padding: 0 8px;
}
.hover-line:hover {
  text-decoration: 1px solid black underline !important;
}
.attachment-card {
  min-width: 10rem;
}

/* user pic */
.user-profile-pic {
  height: 120px;
  width: 120px;
  object-fit: contain;
}

/* Email */
.email-item {
  transition: 0.3s;
}
.email-item:hover {
  box-shadow: 0px 5px 5px 0 rgb(0 0 0 / 10%);
  border-left: 4px solid var(--theme-color);
}

.attachment:hover .main-content {
  opacity: 0.1;
}

.attachment:hover .attachment-overlay {
  display: flex;
}

.attachment-overlay {
  background-color: #3e3e3e4f;
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
}

.attachment-overlay .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin: 8px;
  word-wrap: normal;
}

.attachment-overlay .actions {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 5px;
  margin: 8px;
  color: #fff;
}

.attachment-overlay .actions .item {
  padding: 2px;
  background: #b2b2b2;
  border-color: #b2b2b2;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  max-width: 30px;
}

/* Project */
.construction-table {
  height: 60vh;
}

.stage-photos {
  max-width: 180px;
  max-height: 180px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.w-200 {
  width: 200px;
}
.Collapsible__contentInner {
  max-height: 16rem !important;
  overflow: auto;
  overflow-x: hidden;
}

/* out line Button */

.btn-outline-theme {
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.btn-outline-theme:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.btn-outline-theme:focus,
.btn-outline-theme.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-theme.disabled,
.btn-outline-theme:disabled {
  color: var(--theme-color);
  background-color: transparent;
}

.btn-outline-theme:not(:disabled):not(.disabled):active,
.btn-outline-theme:not(:disabled):not(.disabled).active,
.show > .btn-outline-theme.dropdown-toggle {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.btn-outline-theme:not(:disabled):not(.disabled):active:focus,
.btn-outline-theme:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-theme.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/*
 * ────────────────────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: P R O J E C T   T I T L E   L O A D I N G : :  :   :    :     :        :          :
 * ────────────────────────────────────────────────────────────────────────────────────────────
 */
.lds-ellipsis {
  display: inline-block;
  position: relative;
}
.lds-ellipsis div {
  position: absolute;
  top: 23px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--theme-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* ──────────────────────────────────────────────────────────────────────────────── */
